<template>
  <div class="view">
    <top-nav active="11" :istop="true"></top-nav>
    <div class="topnav_bg_90"></div>
    <div class="about_us">
      <div class="about_us_mission">
        <p class="about_us_mission_title">Services Agreement</p>
      </div>
      <div class="privacy_title">
        <!-- Arcanite Services Pty Ltd -->
        <p>
          BETWEEN:
        </p>
        <p>
          Arcanite Services Pty Ltd (ABN 71 674 390 722) (<strong>Supplier</strong>); and
        </p>
        <p>
          The entity described as the 'Customer' in the Proposal (<strong>Customer</strong>)
        </p>
      </div>
      <div class="user_privacy_agreement">
      <div class="privacy_content">
      <div class="title">
        <div>
          BACKGROUND
        </div>
      </div>
      <div class="content">
          <p>
            A. The Supplier is the owner of the Platform.
          </p>
          <p>
            B. On the request of the Customer, the Supplier has agreed to grant the Customer access to the Platform on the terms and conditions of this agreement.
          </p>
      </div>
      <div class="title">
        Operative Provisions
      </div>
      <div class="content">
        <p>
          1. Definitions and interpretation
        </p>
        <p>
          1.1. Definitions
        </p>
        <p>
          In this agreement the following definitions apply, unless the contrary intention appears or context otherwise requires:
        </p>
        <p>
          (a) Additional Services means the additional services (if any) to be provided by the Supplier to the Customer as described in the ‘Additional Services’ section of the Proposal.
        </p>
        <p>
          (b) Additional Services Fee means the amount (if any) specified as the ‘Additional Services Fee’ in the Proposal.
        </p>
        <p>
          (c) Business Day means a day that is not a Saturday, Sunday, public holiday or bank holiday in New South Wales.
        </p>
        <p>
          (d) Commencement Date means the date specified as the ‘Commencement Date’ in the Proposal.
        </p>
        <p>
          (e) Confidential Information means information in whatever form (including in writing, oral, visual or electronic form wherever located) relating to the business, clients, customers, members, products, affairs or finances of a party and any trade secrets, including technical data and know-how relating to the business of a party or any of their suppliers, clients, customers, agents, distributors, members or management, whether or not such information is marked confidential but does not include:
        </p>
        <p>
          (i) information already known to the receiving party at the time of disclosure by the other party; or
        </p>
        <p>
          (ii) information in the public domain other than as a result of disclosure by a party in breach of its obligations of confidentiality under this agreement.
        </p>
        <p>
          (f) Content means a creative expression and includes, without limitation, video, audio, photographs, images, illustrations, animations, logos, tools, written posts, replies, comments, information, data, text, software, scripts, executable files, graphics, and interactive features, any of which may be generated, provided, or otherwise made accessible on or through the Platform.
        </p>
        <p>
          (g) Customer Data means data owned or supplied by the Customer.
        </p>
        <p>
          (h) Documentation means all technical specifications, user manuals, templates and other documentation supplied by the Supplier to the Customer for the purposes of this agreement. 
        </p>
        <p>
          (i)End User means an individual employee, agent or other personnel of the Customer that is a user of the Platform or interacts with the Platform. 
        </p>
        <p>
          (j) Fees means the Service Fee, Support Fee, Additional Services Fee and Setup Fee.
        </p>
        <p>
          (k) GST means:
        </p>
        <p>
          (i) the same as in the GST Law;
        </p>
        <p>
          (ii) any other goods and services tax, or any tax applying to this agreement in a similar way; and
        </p>
        <p>
          (iii) any additional tax, penalty tax, fine, interest or other charge under a law of such a tax.
        </p>
        <p>
          (l) GST Law means the same as "GST law" in the A New Tax System (Goods and Services Tax) Act 1999 (Cth).
        </p>
        <p>
          (m) Included Software Features means the services specified as the ‘Included Software Features’ in the Proposal.
        </p>
        <p>
          (n) Initial Term means the period specified as the ‘Initial Term’ in the Proposal.
        </p>
        <p>
          (o) Intellectual Property Rights means all present and future rights conferred by statute, common law or equity, whether registered or unregistered, in or in relation to any copyright, trade marks, designs, patents, circuit layouts, business and domain names, inventions, and other results of intellectual activity in the industrial, commercial, scientific, literary or artistic fields, and including the rights to apply for or renew the registration of any such rights.
        </p>
        <p>
          (p)New Release means software which has been provided primarily to implement an extension, alteration, improvement or additional functionality to the Platform.
        </p>
        <p>
          (q) Personal Information means information or an opinion about an individual as defined in section 6 of the Privacy Act 1988 (Cth) which is collected, used, disclosed, stored or handled by the Supplier for the purposes of this agreement.
        </p>
        <p>
          (r) Platform means the enterprise resource management platform known as “Arcanite” and any other platform(s) included in the Included Software Features. 
        </p>
        <p>
          (s) Privacy Policy means the Supplier’s Privacy Policy as amended from time to time and available at https://www.arcanite.com.au/PrivacyPolicy.
        </p>
        <p>
          (t) Property Details means any and all:
        </p>
        <p>
          (i) information with respect to the description, characteristics, availability and price of properties displayed on the Platform; and
        </p>
        <p>
          (ii) images and photographs with respect to properties displayed on the Platform.
        </p>
        <p>
          (u) Proposal means the proposal given by the Supplier to the Customer which sets out the Services and Additional Services (if any) to be provided by the Supplier to the Customer, and the corresponding Fees payable for such services, on the terms and conditions of this Service Agreement.
        </p>
        <p>
          (v) Purpose means enterprise resource planning.
        </p>
        <p>
          (w) Service Fee means the amount specified as the ‘Service Fee’ in the Proposal.
        </p>
        <p>
          (x)Service Levels has the meaning given by APPENDIX 1.
        </p>
        <p>
          (y) Setup Fee means the amount (if any) specified as the ‘Setup Fee’ in the Proposal. 
        </p>
        <p>
          (z) Special Terms means the additional terms and conditions of this agreement (if any) specified as the ‘Special Terms’ in the Proposal.
        </p>
        <p>
          (aa) Support Fee means the amount (if any) specified as the ‘Support Fee’ in the Proposal.
        </p>
        <p>
          (ab) Term means the term of this agreement, which will commence on the Commencement Date for the Initial Term, and continue thereafter for successive periods of one calendar month until terminated by either party in accordance with this agreement.
        </p>
        <p>
          (ac) Terms of Use and Service means the terms of use and service available at https://www.arcanite.com.au as amended from time to time.
        </p>
        <p>
          (ad) Update means software which has been produced primarily to overcome defects in the Platform.
        </p>

        <!-- wwj 1.2.Interpretation
        In this agreement the following rules of interpretation apply, unless the contrary intention appears or context otherwise requires:
        
        
        -->
        <p>
          1.2. Interpretation
        </p>
        <p>
          In this agreement the following rules of interpretation apply, unless the contrary intention appears or context otherwise requires:
        </p>
        <p>
          (a) Words importing the singular include the plural and vice versa and words importing one gender shall include all other genders. 
        </p>
        <p>
          (b) Headings are for ease of reference only and shall not affect the interpretation of this agreement.
        </p>
        <p>
          (c) References to parties are references to the parties to this agreement.
        </p>
        <p>
          (d) Any promise, agreement, representation or warranty given or entered into on the part of two or more persons binds them jointly and severally.
        </p>
        <p>
          (e) No provision of this agreement will be construed adversely to a party because that party was responsible for the preparation of that provision or this agreement.
        </p>
        <p>
          (f) A reference to time is a reference to Sydney time unless otherwise specified.
        </p>
        <p>
          (g) Specifying anything in this agreement after the terms 'include', 'including', 'includes', 'for example', 'such as' or any similar expression does not limit the sense of the words, description, definition, phrase or term preceding those terms unless there is express wording to the contrary.
        </p>
        <p>
          (h) This agreement includes all schedules, annexures, appendices, attachments and exhibits to it.
        </p>
        <p>
          (i) Where a word or phrase is defined, other parts of speech and grammatical forms of that word or phrase have corresponding meanings.
        </p>
      </div>
      <!-- <div class="title">
        2. Access
      </div> -->
      <div class="content">
        <p>
          2. Access
        </p>
        <p>
          2.1. This agreement commences on the Commencement Date and will continue until terminated in accordance with this agreement.
        </p>
        <p>
          2.2. In consideration of the fees payable by the Customer to the Supplier under this agreement, the Supplier:
        </p>
        <p>
          (a) grants to the Customer a non-exclusive, worldwide, non-transferrable licence to access and use the Platform; and
        </p>
        <p>
          (b) agrees to provide or make available to the Customer the Included Software Features, during the Term for the Purpose, on the terms and conditions set out in this agreement.
        </p>
        <p>
          2.3. The Supplier will provide the Customer with access to the Platform on a real-time basis by way of application programming interface and web interface.
        </p>
        <p>
          2.4. The Supplier will make the Platform available to the Customer and its End Users subject to any limitations (for example, with respect to the number of seats to be made available by the Supplier) specified in the Included Software Features.
        </p>
        <p>
          2.5. The Customer acknowledges that the Supplier may apply any Updates or New Releases to the Platform at any time in its sole discretion without notice to the Customer.
        </p>
      </div>
      <!-- <div class="title">
        3. Additional Services
      </div> -->
      <div class="content">
        <p>
          3. Additional Services
        </p>
        <p>
          3.1. In consideration of payment of the Additional Services Fee by the Customer to the Supplier under this agreement, the Supplier will provide the Additional Services to the Customer.
        </p>
        <p>
          3.2. The Customer will provide reasonable assistance to the Supplier on request and do any and all things required to assist the Supplier to perform its obligations under this agreement, including with respect to any Additional Services. 
        </p>
      </div>
      <!-- <div class="title">
        4. Customer obligations
      </div> -->
      <div class="content">
        <p>
          4. Customer obligations
        </p>
        <p>
          4.1. The Customer must not, except as expressly permitted by this agreement:
        </p>
        <p>
          (a) assign, transfer or sub-license any of its rights under this agreement; 
        </p>
        <p>
          (b) permit a third party to access or use the Platform; 
        </p>
        <p>
          (c) access or use the Platform, or allow it to be accessed or used by any of its End Users, in any way other than as permitted under this agreement; or
        </p>
        <p>
          (d) reproduce, translate, adapt, vary or modify the Platform,
        </p>
        <p>
          without the prior written consent of the Supplier.
        </p>
        <p>
          4.2. The Customer must, and must ensure that its End Users:
        </p>
        <p>
          (a) only use the Platform for the Purpose;
        </p>
        <p>
          (b) refrain from using the Platform for any activity which is obscene, indecent, offensive or defamatory;
        </p>
        <p>
          (c) refrain from tampering with, hindering the operation of or making unauthorised modifications to the Platform;
        </p>
        <p>
          (d) refrain from introducing any virus to or from the Platform;
        </p>
        <p>
          (e) refrain from using any part of the Platform in contravention of the Spam Act 2003 (Cth);
        </p>
        <p>
          (f) refrain from uploading onto the Platform any material, or use the Platform in any way:
        </p>
        <p>
          (i) which infringes the Intellectual Property Rights of any person; or 
        </p>
        <p>
          (ii) which is unlawful or violates any law; and
        </p>
        <p>
          (g) refrain from reverse assembling or reverse compiling the Platform or any part of the software in the Platform, except as expressly permitted by sections 47B(3), 47C, 47D, 47E or 47F of the <i>Copyright Act 1968</i> (Cth).
        </p>
        <p>
          4.3. The Customer must:
        </p>
        <p>
          (a) obtain and maintain all applicable licences, approvals and permits, and comply with all applicable laws, to operate its business and (if applicable) sell properties on the Platform; and
        </p>
        <p>
          (b) ensure that, if the Customer requests the Supplier to display properties on the Platforms, the properties will be:
        </p>
        <p>
          (i) as described; and
        </p>
        <p>
          (ii) compliant with all applicable laws, including the requirements and consumer guarantees contained in the Australian Consumer Law.
        </p>
        <p>
          4.4. The Customer agrees to comply with: 
        </p>
        <p>
          (a) all policies notified in writing by the Supplier to the Customer from time to time; and
        </p>
        <p>
          (b) any reasonable requirements and directions of the Supplier with respect to the use of the Platform by the Customer or any End Users.
        </p>
        <p>
          5.1. The Customer:
        </p>
        <p>
          (a) must, and must ensure that its End Users only use the Platform in accordance with the Terms of Use and Service; and
        </p>
        <p>
          (b) acknowledges and agrees that the Supplier may remove access for any End Users who do not use the Platform in accordance with this agreement or the Terms of Use and Service, without refund of any fees or compensation to the Customer.
        </p>
        <p>
          5.2. The Customer agrees to immediately notify the Supplier of any known or suspected breach of the Terms of Use and Service or other unauthorised use of the Platform and to assist the Supplier in the enforcement of the terms of the Terms of Use and Service.
        </p>
        <p>
          5.3. To the extent of any inconsistency between the provisions of this agreement and the Terms of Use and Service, this agreement will prevail.
        </p>
      </div>
      <!-- <div class="title">
        6. Reliance on information
      </div> -->
      <div class="content">
        <p>
          6. Reliance on information
        </p>
        <p>
          6.1. The Platform may contain information uploaded by other users of the Platform (including Property Details). The Customer acknowledges that:
        </p>
        <p>
          (a) the Supplier has not verified or approved this information;
        </p>
        <p>
          (b) the Customer relies on the accuracy, completeness and currency of such information at its sole risk; and 
        </p>
        <p>
          (c) the Supplier accepts no responsibility for the accuracy, completeness or currency of such information.
        </p>
        <p>
          6.2. The Customer must ensure that all images, information and documentation that it provides to the Supplier (including Property Details) or requests the Supplier to display on the Platform is and remains accurate, complete, current and not misleading. 
        </p>
        <p>
          6.3. The Customer must immediately notify the Supplier of any matter or circumstance of which it becomes aware which causes, or is likely to cause, any information or documentation provided to the Supplier by the Customer to be false, inaccurate, misleading or incomplete.
        </p>
        <p>
          6.4. Without limiting clause 6.3, if the Customer provides information to the Supplier to enable the Supplier to display properties on the Platform (including Property Details), the Customer must inform the Supplier in writing immediately if any such information relating to any such properties has changed (including if the property is no longer available, has been sold or has been subject to a price change).
        </p>
        <p>
          6.5. The Customer acknowledges and agrees that:
        </p>
        <p>
          (a) it is solely responsible for conclusions or results that it or its End Users reach or obtain from their use of the Platform; and
        </p>
        <p>
          (b) to the extent any conclusions, results and information generated by the Platform are based on data entered by the Customer or its End Users (or by other users of the Platform), the Supplier excludes any and all responsibility or liability for the completeness, accuracy or correctness of such conclusions, results and information.
        </p>
        <p>
          6.6. Without limiting any other remedies available to the Supplier under this agreement or otherwise, the Customer agrees to indemnify the Supplier against any and all claims, damages, liabilities, losses or expenses suffered or incurred by the Supplier as a result of or in connection with any breach by the Customer of clause 6.2.
        </p>
        <p>
          6.7. For the avoidance of doubt, the indemnity in clause 6.6 will extend to (without limitation) any liabilities, losses or expenses incurred by the Supplier as a result of any third party claim against the Supplier arising from or in connection with the third party having relied (to its detriment) on the accuracy, completeness or currency of information that the Customer or any of its End Users provided to the Supplier or otherwise requested the Supplier to upload to the Platform in connection with a property displayed on the Platform.
        </p>
      </div>
      <!-- <div class="title">
        7. Account Information
      </div> -->
      <div class="content">
        <p>
          7. Account Information
        </p>
        <p>
          7.1. The Customer must ensure that:
        </p>
        <p>
          (a) all passwords, account names, tokens, log in identification or other pieces of information required to access the Platform (Account Information) are treated as confidential information and are kept secure; and 
        </p>
        <p>
          (b) such Account Information is not disclosed to any third party, 
        </p>
        <p>
          and the Customer must ensure that the End Users comply with the obligations in this clause 7.1.
        </p>
        <p>
          7.2. The Supplier may disable any account, username or password, at any time, if in the Supplier’s reasonable opinion:
        </p>
        <p>
          (a) the confidentiality or security of Account Information has been compromised; or
        </p>
        <p>
          (b) a person who has not been granted the right to access the Platform pursuant to this agreement has accessed the Platform using a particular account or Account Information.
        </p>
      </div>
      <!-- <div class="title">
        8. Service Levels
      </div> -->
      <div class="content">
        <p>
          8. Service Levels
        </p>
        <p>
          8.1. The Supplier will guarantee the performance of the Platform in accordance with the Service Levels, and provide such reasonable support, documentation and/or training to the Customer to facilitate use of the Platform as is reasonably requested or required by the Customer.
        </p>
      </div>
      <!-- <div class="title">
        9. Fees 
      </div> -->
      <div class="content">
        <p>
          9. Fees 
        </p>
        <p>
          9.1. The Customer must pay to the Supplier the:
        </p>
        <p>
          (a) Service Fee, in consideration for the licence of the Platform to the Customer by the Supplier pursuant to this agreement;
        </p>
        <p>
          (b) Support Fee, in consideration for the provision of support and maintenance services by the Supplier as set out in this agreement;
        </p>
        <p>
          (c) Additional Services Fee, in consideration for the provision of the Additional Services by the Supplier; and
        </p>
        <p>
          (d) Setup Fee, in consideration for the services provided by the Supplier to on-board and setup the Customer to the Platform.
        </p>
        <p>
          9.2. The Customer must pay to the Supplier the Service Fee, the Support Fee (if any) and the Additional Services Fee (if any) monthly in advance.
        </p>
        <p>
          9.3. The Customer must pay to the Supplier the Setup Fee (if any) on or before the Commencement Date.
        </p>
        <p>
          9.4. The Fees are exclusive of all GST and any other taxes, duties and surcharges that may be payable in connection with the Fees or this agreement.
        </p>
        <p>
          9.5. The Customer agrees that, except in the circumstances set out in clause 17.4, the Fees paid by the Customer to the Supplier are non-refundable.
        </p>
        <p>
          9.6. If payment is not made within:
        </p>
        <p>
          (a) 1 calendar month of the due date, access to the Platform will be restricted pending receipt of payment;
        </p>
        <p>
          (b) 2 calendar months of the due date, access to the Platform will be suspended pending receipt of payment; or
        </p>
        <p>
          (c) 3 calendar months of the due date, the Supplier may terminate with immediate effect by written notice to the Customer.
        </p>
        <p>
          9.7. If payment is not made within 1 calendar month of the due date, interest will be payable by the Customer at the rate of 6 per cent per annum on the overdue amount.
        </p>
        <p>
          9.8. If the Customer disputes the whole or any part of the amount claimed in an invoice submitted by the Supplier pursuant to this agreement, the Customer will pay the undisputed portion on the due date. The dispute regarding the remainder may be referred to the dispute resolution procedure set out in clause 21. If it is subsequently resolved that a further amount is payable, the Customer will pay that amount together with interest at the rate of 6 per cent per annum.
        </p>
        <p>
          9.9. The Customer must pay all amounts payable under this agreement without withholding, deduction, counter claim or set-off.
        </p>
      </div>
      <!-- <div class="title">
        10. Intellectual Property Rights
      </div> -->
      <div class="content">
        <p>
          10. Intellectual Property Rights
        </p>
        <p>
          10.1. The Supplier retains ownership of the Platform whether in its original form or as modified by the Customer during the term of this agreement.
        </p>
        <p>
          10.2. The Customer acknowledges and agrees that all Intellectual Property Rights in the Platform (including text, graphics, logos, icons, sound recordings and software) and the Documentation belong and will belong to the Supplier or the relevant third party owner (as the case may be).
        </p>
        <p>
          10.3. The Customer agrees to be solely responsible for Content and Customer Data uploaded or submitted to the Platform, and the consequences of submitting and publishing its Content and Customer Data on the Platform. The Customer affirms, represents, and warrants that it has the necessary licenses, rights, consents, and permissions to provide the Content and Customer Data that it submits. 
        </p>
        <p>
          10.4. The Customer grants to the Supplier a non-exclusive, transferable, sub-licensable, royalty-free, perpetual, worldwide licence to use any Content and Customer Data uploaded or submitted to the Platform for any lawful purpose, including to perform the Supplier’s obligations under this agreement.
        </p>
        <p>
          10.5. The Customer warrants that Content and Customer Data it submits to the Platform will not contain third party copyrighted material, or material that is subject to other third party proprietary rights, unless the Customer is otherwise legally entitled to post the material and to grant to the Supplier all of the rights required for the Supplier to perform its obligations under this agreement.
        </p>
        <p>
          10.6. The Customer grants to the Supplier a non-exclusive, sub-licensable, royalty-free, perpetual, worldwide licence to use any trade marks, logos or other branding or images owned or controlled by the Customer for the Supplier’s marketing and promotional purposes.
        </p>
        <p>
          10.7. Subject to clause 10.8, the Supplier warrants that the Customer's use of or access to the Platform does not infringe the Intellectual Property Rights of any third party.
        </p>
        <p>
          10.8. The Supplier shall not be liable to the Customer in connection with a breach of the warranty in clause 10.7 if:
        </p>
        <p>
          (a) the Customer does not notify the Supplier of the other person's claim or of infringement of copyright within seven days after becoming aware of the claim;
        </p>
        <p>
          (b) the Supplier's ability to defend the claim has been prejudiced by the Customer's non-compliance with any of its obligations under this agreement;
        </p>
        <p>
          (c) the Customer does not give the Supplier reasonable assistance in defending the claim;
        </p>
        <p>
          (d) the claim has arisen because of the use of the Platform in combination with equipment, materials or computer programs not supplied or approved by the Supplier; or
        </p>
        <p>
          (e) the Customer does not permit the Supplier to have control of the defence of the claim and all related settlement negotiations.
        </p>
      </div>
      <!-- <div class="title">
        11. Privacy
      </div> -->
      <div class="content">
        <p>
          11. Privacy
        </p>
        <p>
          11.1. The Customer warrants that:
        </p>
        <p>
          (a) it has made all necessary notifications to, and obtained all necessary consents from, the individuals whose Personal Information it is disclosing to the Supplier in the course of this agreement to enable the Supplier to perform its obligations in accordance with this agreement; and
        </p>
        <p>
          (b) it will comply with the <i>Privacy Act 1988</i> (Cth) in respect of the disclosure of such Personal Information to the Supplier in connection with this agreement or the Customer’s (or its End Users’) use of the Platform.
        </p>
        <p>
          11.2. The Customer indemnifies the Supplier for any claim brought in connection with any act or omission by the Supplier in relation to a third party's or End User’s Personal Information to the extent that such act or omission resulted directly from the Customer's instructions or a breach by the Customer of the warranties in clause 11.1.
        </p>
        <p>
          11.3. The Supplier warrants that it will only use any Personal Information which is disclosed or provided to it by the Customer or the End Users in the course of this agreement for the purpose of the Supplier performing its obligations under this agreement.
        </p>
      </div>
      <!-- <div class="title">
        12. Data security
      </div> -->
      <div class="content">
        <p>
          12. Data security
        </p>
        <p>
          12.1. The Customer will take all reasonable steps to protect the Platform from unauthorised access, use or damage.
        </p>
        <p>
          12.2. The Supplier will take all reasonable steps to protect Personal Information in its possession against misuse or loss, and will ensure that Personal Information is managed in accordance with the Privacy Policy.
        </p>
      </div>
      <!-- <div class="title">
        13. Third party costs
      </div> -->
      <div class="content">
        <p>
          13. Third party costs
        </p>
        <p>
          13.1. The Customer bears all risk of failure of, or issues with, services provided by external or third party service providers engaged by the Customer in connection with its access to or use of the Platform, and is solely responsible for any and all associated costs and expenses.
        </p>
      </div>
      <!-- <div class="title">
        14. Indemnity and liability
      </div> -->
      <div class="content">
        <p>
          14. Indemnity and liability
        </p>
        <p>
          14.1. The Customer will indemnify and hold harmless the Supplier against any liability, loss, damage, injury, claim or cost (including legal costs on a full indemnity basis) sustained or incurred by the Supplier which arises from or in connection with:
        </p>
        <p>
          (a) a breach of any provision of this agreement or the Terms of Use and Service by the Customer, its directors, officers, employees, agents or contractors, or any of its End Users; or
        </p>
        <p>
          (b) a claim by any third party that arises from or in connection with such a breach.
        </p>
        <p>
          14.2. Despite any other provision of this agreement, the Supplier’s total aggregate liability to the Customer in any 12 month period, in respect of any act or omission of the Supplier in connection with its obligations under this agreement, will not exceed the sum of Fees paid by the Customer to the Supplier under this agreement during the 12 month period preceding the event or matter giving rise to the claim.
        </p>
        <p>
          14.3. This clause 14 will survive termination of this agreement.
        </p>
      </div>
      <!-- <div class="title">
        15. Disclaimers
      </div> -->
      <div class="content">
        <p>
          15. Disclaimers
        </p>
        <p>
          15.1. Other than as expressly set out in this agreement, the Supplier makes no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the Platform or the information, services, or related graphics contained on the Platform to the maximum extent permitted by law.
        </p>
        <p>
          15.2. Without limiting clause 15.1, the Supplier does not accept responsibility for any destruction, loss or damage to the Customer’s data or information arising from the Customer’s use of the Platform. The Customer is responsible for keeping a back up copy of all data and other information provided to the Supplier or input into the Platform.
        </p>
        <p>
          15.3. The Customer acknowledges that the Platform may contain content, images, information and other media generated by users of the Platform (including, without limitation, with respect to properties displayed on the Platform). The Supplier makes no representations or warranties of any kind, express or implied, about:
        </p>
        <p>
          (a) the completeness, accuracy, reliability, suitability or availability with respect to such content, images, information or other media; or
        </p>
        <p>
          (b) the properties displayed on the Platform, including whether they are fit for purpose or that any statements or representations about them are accurate, current or complete.
        </p>
        <p>
          15.4. The Customer acknowledges and agrees that the Supplier makes no representation or warranty with respect to the continuous accessibility or availability of the Platform, or that the Platform will achieve any or a certain number of sales, impressions, click throughs, exposure or other metrics. 
        </p>
        <p>
          15.5. The Supplier will not be liable to the Customer for the Platform becoming temporarily unavailable as a result of technical or other issues arising which are beyond the Supplier’s reasonable control.
        </p>
        <p>
          15.6. The Supplier also accepts no responsibility or liability for any contract of sale in relation to a property displayed on the Platform not completing as a result of or in connection with the Platform (including as a result of the Platform not being continuously accessible or available).
        </p>
        <p>
          15.7. Nothing in this agreement limits or excludes any guarantees, warranties, representations or conditions implied or imposed by law, including the Australian Consumer Law, or any liability under them, which by law cannot be limited or excluded.
        </p>
        <p>
          15.8. If the Supplier is liable to the Customer in relation to a failure to comply with a guarantee that applies under the Australian Consumer Law that cannot be excluded, the Supplier's total liability to the Customer for that failure is limited to, at the option of the Supplier:
        </p>
        <p>
          (a) in the case of services, the resupply of the services or the payment of the cost of resupply; and
        </p>
        <p>
          (b) in the case of goods, the replacement of the goods or the supply of equivalent goods, or the repair of the goods, or the payment of the cost of replacing the goods or of acquiring equivalent goods, or the payment of the cost of having the goods repaired.
        </p>
        <p>
          15.9. The liability of each party to the other party under this agreement will be reduced proportionately to the extent that an act or omission of the other party causes the loss or liability for which the first party is liable.
        </p>
      </div>
      <!-- <div class="title">
        16. Termination
      </div> -->
      <div class="content">
        <p>
          16. Termination
        </p>
        <p>
          16.1. A party may terminate this agreement with immediate effect by written notice to the other party if:
        </p>
        <p>
          (a) the other party commits a breach of any of its material obligations under this agreement and:
        </p>
        <p>
          (i) the breach is irremediable; or
        </p>
        <p>
          (ii) the other party fails to remedy the breach within 10 Business Days of being given notice to do so;
        </p>
        <p>
          (b) any type of insolvency administrator or external administrator is appointed to the other party or the assets of the other party; or
        </p>
        <p>
          (c) the other party enters into any scheme, composition or arrangement with any of its creditors.
        </p>
        <p>
          16.2. The Supplier may terminate this agreement with immediate effect by written notice to the Customer if:
        </p>
        <p>
          (a) the Customer ceases to conduct its business;
        </p>
        <p>
          (b) the Customer or any End Users use the Platform for any purpose other than the Purpose; or
        </p>
        <p>
          (c) pursuant to clause 9.6(c).
        </p>
        <p>
          16.3. Either party may terminate this agreement by 20 Business Days’ notice to the other party following the conclusion of the Initial Term.
        </p>
        <p>
          16.4. Any termination of this agreement shall not affect any accrued rights or liabilities of either party.  Any obligation of indemnity, or other provision of this agreement which is expressly or by implication intended to continue in force after termination, will survive termination of this agreement.
        </p>
      </div>
      <!-- <div class="title">
        17. Obligations on termination
      </div> -->
      <div class="content">
        <p>
          17. Obligations on termination
        </p>
        <p>
          17.1. On termination of this agreement for any reason:
        </p>
        <p>
          (a) the licence and other rights to access and use the Platform granted to the Customer and the End Users under this agreement will automatically and immediately cease; and
        </p>
        <p>
          (b) the Customer must immediately cease using the Platform and procure that the End Users immediately cease using the Platform.
        </p>
        <p>
          17.2. On termination of this agreement, on request of the Customer, the Supplier will provide reasonable assistance to the Customer to enable the transfer of Customer Data to a third party nominated by the Customer, subject to the Customer paying to the Supplier fees to provide such file transfer services calculated by the Supplier at a reasonable market rate.
        </p>
        <p>
          17.3. Subject to clause 17.4, on termination of this agreement, the Customer must immediately pay to the Supplier any and all outstanding amounts due to the Supplier under this agreement (which have become due for payment at any time up to the date of termination).
        </p>
        <p>
          17.4. If the Customer validly terminates this agreement pursuant to clause 16.1, the Supplier must refund to the Customer any Service Fee amounts which have been paid in advance to the Supplier which relate to the period after the date of termination (calculated on a pro rata basis).
        </p>
        <p>
          17.5. For the avoidance of doubt, if this agreement is terminated in any circumstance other than by the Customer pursuant to clause 16.1, the Supplier is entitled to retain any Fees paid to the Supplier and to recover any outstanding Fees due to the Supplier which have become due for payment at any time up to the date of termination.
        </p>
      </div>
      <!-- <div class="title">
        18. Confidentiality
      </div> -->
      <div class="content">
        <p>
          18. Confidentiality
        </p>
        <p>
          18.1. Subject to clause 18.2, each party must not disclose or allow to be disclosed to any third party any Confidential Information of the other party, and shall only use such Confidential Information for the purposes of this agreement.
        </p>
        <p>
          18.2. A party may only make a disclosure in relation to information referred to in clause 18.1:
        </p>
        <p>
          (a) if compelled to do so by law or required to do so by any regulatory body or government agency or body including any relevant stock exchange;
        </p>
        <p>
          (b) if the disclosure is made to its employees, bankers, accountants, auditors, legal or other advisors who are bound by confidentiality constraints; or
        </p>
        <p>
          (c) with the prior written consent of the other party.
        </p>
        <p>
          18.3. Each party must take or cause to be taken all reasonable precautions to protect the confidentiality of any Confidential Information of the other party.
        </p>
        <p>
          18.4. On termination of this agreement:
        </p>
        <p>
          (a) each party must continue to keep confidential all Confidential Information of the other party; and
        </p>
        <p>
          (b) the Customer must:
        </p>
        <p>
          (i) immediately return to the Supplier all documents (whether in original or copied form) in the Customer’s possession or control comprising or containing Confidential Information of the Supplier; and
        </p>
        <p>
          (ii) except where to do so would contravene any legal obligation, destroy all electronic files containing Confidential Information including all cache, back-up or archive copies of them.
        </p>
        <p>
          18.5. This clause 18 will survive the termination of this agreement.
        </p>
      </div>
      <!-- <div class="title">
        19. GST
      </div> -->
      <div class="content">
        <p>
          19. GST
        </p>
        <p>
          19.1. Words defined in the GST Law have the same meaning in this clause 19, unless the context makes it clear that a different meaning is intended.
        </p>
        <p>
          19.2. In addition to paying the Fees and any other amounts payable under or in connection with this agreement (which are exclusive of GST), the Customer will:
        </p>
        <p>
          (a) pay to the Supplier an amount equal to any GST payable on any supply by the Supplier in respect of which the Fees or other amounts are payable under this agreement; and
        </p>
        <p>
          (b) make such payment either on the date when the relevant Fees are due or within 7 days after the Customer is issued with a tax invoice, whichever is the later.
        </p>
        <p>
          19.3. The Supplier must, within 28 days of request from the Customer, issue a tax invoice (or an adjustment note) to the Customer for any supply under or in connection with this agreement.
        </p>
        <p>
          19.4. The Supplier will promptly create an adjustment note for (and apply to the Commissioner of Taxation for) a refund, and refund to the Customer, any overpayment by the Customer for GST but the Supplier need not refund to the Customer any amount for GST paid to the Commissioner of Taxation unless the Supplier has received a refund or credit for that amount.
        </p>
      </div>
      <!-- <div class="title">
        20. Special Terms
      </div> -->
      <div class="content">
        <p>
          20. Special Terms
        </p>
        <p>
          20.1. The parties must comply with the Special Terms (if any).
        </p>
        <p>
          20.2. To the extent that the Special Terms are different to the terms set out in this agreement, the parties agree that this agreement will be deemed to have been amended in accordance with the Special Terms and this agreement will be read and construed accordingly.
        </p>
        <p>
          20.3. To the extent of any inconsistency between the provisions of this agreement and the Special Terms, the Special Terms will prevail.
        </p>
      </div>
      <!-- <div class="title">
        21. Dispute resolution
      </div> -->
      <div class="content">
        <p>
          21. Dispute resolution
        </p>
        <p>
          (a) If a dispute arises between the parties as to:
        </p>
        <p>
          (i) the construction of this agreement; or
        </p>
        <p>
          (ii) the rights or obligations of a party under this agreement,
        </p>
        <p>
          a party may give to the other parties a notice of dispute in writing (the Dispute Notice) and the parties undertake in good faith to use all reasonable endeavours to settle the dispute within 15 Business Days of receipt of the Dispute Notice.
        </p>
        <p>
          (b) Each party must continue to perform its obligations under this agreement despite the existence of a dispute.
        </p>
        <p>
          (c) If the dispute the subject of the Dispute Notice has not been resolved within 15 Business Days of receipt of the Dispute Notice (or such later date as agreed in writing between the parties), the parties must refer the dispute to mediation in accordance with and subject to the Resolution Institute’s ‘Mediation Rules’.
        </p>
        <p>
          (d) A party may not start arbitration or court proceedings (except proceedings seeking injunction or other interlocutory relief) in respect of a dispute, unless it has complied with this clause 21.
        </p>
      </div>
      <!-- <div class="title">
        22. General
      </div> -->
      <div class="content">
        <p>
          22. General
        </p>
        <p>
          22.1. Entire agreement
        </p>
        <p>
          This agreement supersedes all prior agreements, arrangements and undertakings between the parties and constitutes the entire agreement between the parties relating to the subject matter of this agreement. 
        </p>
        <p>
          22.2. Costs 
        </p>
        <p>
          Except as expressly provided in this agreement, each party must pay its own costs incurred in connection with the negotiation, preparation and execution of this agreement.
        </p>
        <p>
          22.3. Notices
        </p>
        <p>
          All notices which are required to be given under this agreement must be in writing and must be sent to the address of the recipient set out in the ‘Address for Service of Notices’ section of the Proposal or such other address as the recipient may designate by notice given in accordance with this clause 22.3. Any notice may be delivered by hand or by prepaid post or email. Any such notice will be deemed to have been served:
        </p>
        <p>
          (a) (if delivered by hand) when delivered; or 
        </p>
        <p>
          (b) (if by prepaid post) 48 hours after posting; or 
        </p>
        <p>
          (c) (if by email) five hours after the time sent (as recorded on the device from which the sender sent the email) unless the sender receives an automated message that the email has not been delivered.
        </p>
        <p>
          22.4. Assignment
        </p>
        <p>
          Neither party shall assign, whether in whole or part, the benefit of this agreement or any rights or obligations hereunder, without the prior written consent of the other party.
        </p>
        <p>
          22.5. Governing law
        </p>
        <p>
          This agreement shall be governed by and construed in accordance with the laws for the time being in force in New South Wales, Australia and the parties agree to submit to the exclusive jurisdiction of the courts and tribunals of New South Wales, Australia.
        </p>
        <p>
          22.6. Waiver
        </p>
        <p>
          No forbearance, delay or indulgence by a party in enforcing the provisions of this agreement shall prejudice or restrict the rights of that party, nor shall any waiver of those rights operate as a waiver of any subsequent breach.  Any waiver must be in writing, signed by the party granting the waiver and is only effective to the extent set out in that written waiver.
        </p>
        <p>
          22.7. Variation
        </p>
        <p>
          No variation of this agreement will be effective unless it is in writing and signed by both parties.
        </p>
        <p>
          22.8. Agency, partnership etc
        </p>
        <p>
          (a) Nothing in this agreement gives a party authority to bind the other party in any way.
        </p>
        <p>
          (b) This agreement shall not constitute or imply any partnership, employment, joint venture, agency, fiduciary relationship or other relationship between the parties other than the contractual relationship expressly provided for in this agreement.
        </p>
        <p>
          22.9. Severability
        </p>
        <p>
          Should any part of this agreement be or become invalid, that part shall be severed from this agreement. Such invalidity shall not affect the validity of the remaining provisions of the agreement.
        </p>
        <p>
          22.10. Counterparts
        </p>
        <p>
          This agreement may be executed in any number of counterparts. All counterparts taken together constitute one instrument. A party may execute this agreement by signing any counterpart. Counterparts may be signed and exchanged electronically.
        </p>
      </div>
      <div class="title">
        <div>Appendix 1</div>
        <div>Service Level Guarantee</div>
        <!-- <div>1. Definitions and interpretation</div> -->
      </div>
      <div class="content">
        <p>
          1. Definitions and interpretation
        </p>
        <p>
          1.1. Definitions
        </p>
        <p>
          In this APPENDIX 1, the following definitions apply, unless the contrary intention appears or context otherwise requires:
        </p>
        <p>
          (a) Downtime means the Platform is unavailable for a Customer or End Users, exclusive of Permitted Downtime or Third Party Downtime.
        </p>
        <p>
          (b) Monthly Uptime Percentage means, in respect of a calendar month, the total number of minutes in the month minus the number of minutes of Downtime suffered in the month, divided by the total number of minutes in the month.
        </p>
        <p>
          (c) Permitted Downtime means the Platform is unavailable for use by the Customer or End Users during a pre-scheduled period which has been announced by the Supplier at least 72 hours in advance. Unless otherwise communicated, scheduled downtime shall not be more than 2 hours per week, for major system configuration changes, upgrades or normal maintenance.
        </p>
        <p>
          (d) Third Party Downtime means any disruption to services, or any part of the services provided under this agreement arising out of the action or inaction of any third party.
        </p>
        <p>
          (e) Service Credit means the following:
        </p>
        <p>
          <table width="400" border="1">
            <tr>
            <th align="left">Monthly Uptime Percentage<br/><br/></th>
            <th align="left">Number of days of service provided at no charge to Customer<br/><br/></th>
            </tr>
            <tr>
            <td align="left">< 99.9% - >= 99.0%<br/><br/></td>
            <td align="left">1<br/><br/></td>
            </tr>
            <tr>
            <td align="left">< 99.0% - >= 95.0%<br/><br/></td>
            <td align="left">10<br/><br/></td>
            </tr>
            <tr>
            <td align="left">< 95.0%<br/><br/></td>
            <td align="left">30<br/><br/></td>
            </tr>
          </table>
        </p>
        <p>
          (f) Service Level Guarantee means this APPENDIX 1.
        </p>
      </div>
      <!-- <div class="title">
        2. Operative Terms
      </div> -->
      <div class="content">
        <p>
          2. Operative Terms
        </p>
        <p>
          2.1. Customer to Request Service Credit.
        </p>
        <p>
          In order to receive any of the Service Credits described above, the Customer must notify the Supplier in writing within 30 days from the time that the Customer becomes eligible to receive a Service Credit. Failure to comply with this requirement will forfeit the Customer’s right to receive a Service Credit.
        </p>
        <p>
          2.2. Claims
        </p>
        <p>
          The Customer must submit supporting documentation including all information necessary for the Supplier to validate the claim for a Service Credit under this Service Level Guarantee, including but not limited to: 
        </p>
        <p>
          (a) a detailed description of the occurance giving rise to the claim; 
        </p>
        <p>
          (b) information regarding the time and duration of any downtime; and
        </p>
        <p>
          (c) the number and location(s) of affected users (if applicable).
        </p>
        <p>
          2.3. Maximum Service Credit
        </p>
        <p>
          The aggregate maximum number of Service Credits that the Supplier will provide to the Customer in a single calendar month shall not exceed 30 days at no charge to the Customer. 
        </p>
        <p>
          2.4. Exclusions
        </p>
        <p>
          This Service Level Guarantee does not apply to, and Service Credits will not be available to the Customer in respect of, any services that expressly exclude this Service Level Guarantee (as stated in the documentation for such services) or any performance issues that:
        </p>
        <p>
          (a) are related to acts of nature, forces or causes beyond the Supplier’s reasonable control, including (without limitation) strikes, labour disputes, riots, insurrections, civil disturbances, explosions, acts of god, pandemic, war, governmental actions, orders or directions, or orders of domestic or foreign courts or tribunals; 
        </p>
        <p>
          (b) result from availability of third-party APIs or services; or
        </p>
        <p>
          (c) result from a Customer’s equipment.
        </p>
      </div>
      <div class="content" style="marginTop:20px"><p>Date of Last Revision: Jul 15th, 2022</p>
</div>
     
     <!-- -->
    </div>
      </div>
    </div>
    <foot-nav></foot-nav>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: '11',
    }
  },
  created() {

  },
  methods: {
    goRouter(path) {
      this.$router.push(path)
    },
    goBack() {
      window.history.go(-1)
    }
  },
  components: {
    "top-nav": resolve => require(["./TopNav.vue"], resolve),
    "foot-nav": resolve => require(["./FooterNav.vue"], resolve),
  },
}
</script>
<style lang="scss" scoped>
.view {
  min-height: 100%;
  background: #f7f7f7;
  .about_us {
    background: transparent url("../../assets/imgs/pc/privacy.png") no-repeat
      center 0;
    background-size: 2560px 304px;
    .about_us_mission {
      width: 724px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      padding-top: 128px;
      text-align: center;
      .about_us_mission_title {
        font-family: Poppins-Bold;
        font-size: 36px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
      .about_us_mission_content {
        font-family: Poppins;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.5;
        letter-spacing: normal;
        margin-top: 20px;
        text-align: center;
        color: #fff;
      }
      .quote_img {
        width: 42px;
        height: 36px;
        position: absolute;
        right: -42px;
        top: 145px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .privacy_title {
      p{
        margin-top: 40px;
        font-family: Poppins;
        font-size: 20px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: left;
        color: #062440;
        width: 1170px;
        margin-left: auto;
        margin-right: auto;
      }
      margin-top: 164px;
      font-family: Poppins-Bold;
      font-size: 20px;
      // font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      text-align: left;
      color: #062440;
      width: 1170px;
      margin-left: auto;
      margin-right: auto;
    }
    .privacy_content {
      width: 1170px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 160px;
      .title {
        margin-top: 40px;
        font-family: Poppins-Bold;
        font-size: 20px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: left;
        color: #062440;
        div{
          margin-top: 40px;
          font-family: Poppins-Bold;
          font-size: 20px;
          // font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.3;
          letter-spacing: normal;
          text-align: left;
          color: #062440;
        }
      }
      .content {
        p{
          margin-top: 40px;
          font-family: Poppins;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.63;
          letter-spacing: normal;
          text-align: left;
          color: #314455;
          span{
            font-family: Poppins;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            letter-spacing: normal;
            text-align: left;
            color: #314455;
          }
        }
      }
    }
  }
}
</style>